
                    @import '~@fairstone/style/themes/fairstone/abstracts/mixins';
                    @import 'src/styles/global';
                
.container {
    display: flex;
    justify-content: center;
    padding-top: 10vh;
    padding-bottom: 10vh;
    overflow-x: hidden;
    height: 100%;

    @include respond(phone) {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.screen {
    margin: auto;
    max-width: 64rem;
    min-height: 55rem;
    padding: 3.5rem;
    position: relative;
    background-color: rgba(82, 53, 110, 0.7);
    border-color: #{var(--neutral-black-55)};
    text-align: center;

    @include respond(phone) {
        height: 100%;
        width: 100%;
        padding: 0rem;
        border: none;
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        height: 7px;
        background-color: #{var(--tertiary-light-color)};
    }

    .header,
    .text {
        color: #{var(--neutral-black-0)};
        margin-bottom: 4rem;
    }

    .icon {
        font-size: 9rem;
        margin-bottom: 2rem;
        color: #{var(--neutral-black-0)};
    }

}