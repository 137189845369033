
                    @import '~@fairstone/style/themes/fairstone/abstracts/mixins';
                    @import 'src/styles/global';
                
.container {
    height: 100%;
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title {
    color: #{var(--error-color)};
}

.margin {
    margin-top: 1em;
    margin-bottom: 1em;
}

.centerChildren {
    display: flex;
    align-items: center;
    flex-direction: column;
}
