
                    @import '~@fairstone/style/themes/fairstone/abstracts/mixins';
                    @import 'src/styles/global';
                
.commonTips {
    margin: 1.5rem 0;
    display: grid;
    text-align: left;
    background: #{var(--neutral-black-0)};

    h3,
    p {
        margin-bottom: 2rem;
    }

    .link {
        display: flex;
        align-items: center;
        text-decoration: none;
        font-size: 1.6rem;

        .nextIcon {
            margin-top: 0.1rem;
            font-size: larger;
        }
    }
}