
                    @import '~@fairstone/style/themes/fairstone/abstracts/mixins';
                    @import 'src/styles/global';
                
body {
    background-color: #{var(--neutral-black-3)};
}

.appWrapper {
    position: relative;
    display: grid;
    grid-template-areas:
        'header'
        'main';
    grid-template-rows: auto 1fr;
    min-height: 100vh;
    height: 100%;
    min-width: 32rem;

    background-image: url('/images/loan-app-bg.jpg');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: 66% top;
    @media (max-width: 1000px) {
        background-size: 1000px 761px; /* Force the image to its minimum width */
    }

    background-color: #{var(--primary-dark-color)};

    .header {
        grid-area: header;
        height: 12rem;
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
        z-index: 1002;

        @include respond(tab-port) {
            height: 6rem;
        }
    }

    .main {
        grid-area: main;
        width: 100%;
        height: 100%;
        padding-top: 12rem;

        @include respond(tab-port) {
            padding-top: 6rem;
        }
    }
}
